export const  quizData = [
    {
        id: 1,
        question: "Which statement best describes you?",
        description : "Select one.",
        options: [
            {
                text : "I'm interested in owning an EV myself.",
                idiot: "Hungry Idiot"
            },
            {
                text : "I'm sure there are pros and cons with EVs.",
                idiot: "Curious Idiot"
            },
            {
                text : "I'm not letting you take my gas vehicle away.",
                idiot: "Angry Idiot"
            },
        ]
    },
    {
        id: 2,
        question: "What keeps you up at night?",
        description : "Select one.",
        options: [
            {
                text : "The agenda to force people to switch from superior performance gas vehicles to unreliable electric vehicles",
                idiot: "Angry Idiot"
            },
            {
                text : "It's hard to know what's real and what's fake news when it comes to EVs.",
                idiot: "Curious Idiot"
            },
        ],
    },
    {
        id: 3,
        question: "What excites you most about technology?",
        description : "Select one.",
        options: [
            {
                text : "Advancements in clean energy.",
                idiot: "Hungry Idiot"
            },
            {
                text : "Fast internet and smarter gadgets.",
                idiot: "Curious Idiot"
            },
        ]
    }
];