import evAdvocate from '../images/evAdvocate.png';
import hungryIdiot from '../images/hungryIdiot.png';
import curiousIdiot from '../images/curiousIdiot.png';
import angryIdiot from '../images/angryIdiot.png';

export const idiotData = [
    {
        "profile": {
            "title": "EV Advocate",
            "quote": "I am passionate and knowledgeable about electric vehicles",
            "image": evAdvocate
        },
        "typicalTraits": {
            "age": "Pre-smartphone",
            "brainUpgrade" : "B.A. / B.S.",
            "openToChange": "True",
            "career": "Technology"
        },
        "summary":
            "Avid advocate for electric vehicles and deeply knowledgeable about their benefits and advancements in the field. Spends a significant amount of time participating in online forums, engaging with enthusiasts and skeptics alike, and sharing accurate information about EVs to dispel myths and misconceptions.",
        "goals": [
            "Combat misinformation about EVs.",
            "Encourage more people to consider switching to EVs.",
            "Build a supportive community of EV enthusiasts.",
            "Influence policy changes at local and national levels to incentivize the adoption of EVs."
        ],
        "frustrations": [
            "There is a lot of resistance from people who are skeptical about EVs and powerful business interests.",
            "Time: Balancing advocacy with personal life, defending against misinformation.",
            "There isn't a 'one-stop-shop' for quality, factual info about EVs.",
            "EV misinformation is rampant."
        ]
    },
    {
        "profile": {
            "title": "Hungry Idiot",
            "quote": "I am interested in owning an electric vehicle myself",
            "image": hungryIdiot
        },
        "typicalTraits": {
            "age": "Pre-smartphone",
            "brainUpgrade" : "B.A. / B.S.",
            "openToChange": "True",
            "career": "Technology"
        },
        "summary":
            "Has a keen interest in sustainability and technology, and has always been fascinated by innovative solutions that have a positive impact on the environment. Actively researching electronic vehicles as she considers making the switch from a traditional gas-powered car. Eager to learn more about their features, benefits, and options. ",
        "goals": [
            "Find an EV that meets her needs and preferences.",
            "Gain a comprehensive understanding of EV technology.",
            "Evaluate the long-term cost savings with owning an EV, including incentives and rebates.",
        ],
        "frustrations": [
            "It's overwhelming to navigate through all the information available online and understand what's true.",
            "Concerned and confused about range limitations of EVs.",
            "Difficult to find EV dealerships nearby to test drive.",
        ]
    },
    {
        "profile": {
            "title": "Curious Idiot",
            "quote": "I'm sure there are pros and cons",
            "image": curiousIdiot
        },
        "typicalTraits": {
            "age": "Pre-smartphone",
            "brainUpgrade" : "B.A. / B.S.",
            "openToChange": "True",
            "career": "Technology"
        },
        "summary":
            "Maintains a neutral stance towards electric vehicles and is open to learning both the positives and negatives about them. Understands the potential environmental benefits of EVs but is also cautious about the potential drawbacks and uncertainties associated with this emerging technology.",
        "goals": [
            "Gather comprehensive information about EVs.",
            "Carefully evaluate his options and compare EVs with gas-powered vehicles.",
            "Minimize potential risks associated with his purchasing decision.",
        ],
        "frustrations": [
            "It's challenging to navigate through the abundance of differing information online about EVs.",
            "Uncertain about the reliability and longevity of EV technology.",
            "Time consuming to assess the cost implications of owning an EV vs. a hybrid or traditional car.",
        ]
    },
    {
        "profile": {
            "title": "Angry Idiot",
            "quote": "I'm not letting you take my gas car away",
            "image": angryIdiot
        },
        "typicalTraits": {
            "age": "Pre-smartphone",
            "brainUpgrade" : "B.A. / B.S.",
            "openToChange": "False",
            "career": "Technology"
        },
        "summary":
            "Strongly opposes electric vehicles and believes they threaten his way of life and the gas-powered vehicles he has grown to trust. Often misinformed about EVs and is susceptible to conspiracy theories. Actively participates in online discussions and social media, sharing and endorsing content that aligns with his negative views on EVs.",
        "goals": [
            "Defend the continued use of gas-powered vehicles.",
            "Find validation in online communities where others share his views.",
            "Maintain the status quo and avoid “unnecessary complications” and perceived risks with EVs.",
        ],
        "frustrations": [
            "Don't want to change the way things have always been.",
            "Thinks there's an agenda to force him to switch from something superior in performance and dependability to something unreliable.",
            "His aggressive approach to discussions can alienate him.",
        ]
    }
];