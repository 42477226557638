import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { idiotData } from '../../assets/sampleContent/idiotBadges';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { StarBorder } from '@mui/icons-material';


export default function PersonaModal({ modalOpen, handleCloseModal, idiotType }){

    const [open, setOpen] = useState(false);
    const [profileData, setProfileData] = useState({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const data = idiotData.filter((data) => data.profile.title === idiotType);
        setProfileData(data[0]);
    }, [idiotType]);

    useEffect(() => {
        setOpen(modalOpen);
    }, [modalOpen]);

    const handleClose = () => {
        setOpen(false);
        handleCloseModal(false);
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogContent sx={{ padding : 0 }}>
                <Box sx={{ height : "100px", width : "100%", bgcolor: "#E2E2E5", display: "flex", justifyContent: "end" }}>
                    <CloseIcon sx={{ marginRight: "10px", marginTop: "10px", cursor: "pointer" }} onClick={handleClose} />
                </Box>
                <Box sx={ isMobile ? { padding: 2, margin: "auto", marginTop : "-80px" } : { padding: 4, margin: "auto", marginTop : "-80px" }}>
                    <Box textAlign="center" mb={4}>
                        <Avatar
                            sx={{ width: 100, height: 100, margin: "auto" }}
                            alt="Profile Picture"
                            src={profileData?.profile?.image}
                        />
                        <Typography variant="h5" fontWeight="bold" mt={2}>
                            {profileData?.profile?.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" mt={2}>
                            "{profileData?.profile?.quote}"
                        </Typography>
                    </Box>
                    <Divider sx={{ my : 3 }} />
                    <Box>
                        <Typography variant="h6" gutterBottom fontWeight="700">
                                TYPICAL TRAITS
                        </Typography>
                        <Grid container spacing={4} mb={4} mt={1}>
                            <Grid container item spacing={4} sm={6}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" >Age:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight : "700", fontStyle: "italic" }}>{profileData?.typicalTraits?.age}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" >Brain Upgrade:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight : "700", fontStyle: "italic" }}>{profileData?.typicalTraits?.brainUpgrade}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={4} sm={6}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" >Open To Change:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight : "700", fontStyle: "italic" }}>{profileData?.typicalTraits?.openToChange}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <Typography color="textSecondary" >Career:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontWeight : "700", fontStyle: "italic" }}>{profileData?.typicalTraits?.career}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Box>

                    <Divider sx={{ my : 3 }} />

                    <Box mb={4}>
                        <Typography variant="h6" gutterBottom fontWeight="700">
                            SUMMARY
                        </Typography>
                        <Typography mt={1}>{profileData?.summary}</Typography>
                    </Box>

                    <Divider sx={{ my : 3 }} />

                    <Grid container spacing={4}>
                        <Grid item sm={6} mb={4}>
                            <Typography variant="h6" gutterBottom fontWeight="700">
                                GOALS
                            </Typography>
                            <List>
                                {profileData?.goals?.map((goal, index) => (
                                    <ListItem key={index}>
                                        <StarBorder mr={5} sx={{ marginRight : "10px", color : "#6FAD3F" }} />
                                        <ListItemText primary={goal} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>

                        <Grid item sm={6} mb={4}>
                            <Box>
                                <Typography variant="h6" gutterBottom fontWeight="700">
                                    FRUSTRATIONS
                                </Typography>
                                <List>
                                    {profileData?.frustrations?.map((frustration, index) => (
                                        <ListItem key={index}>
                                            <SentimentVeryDissatisfiedIcon sx={{ marginRight : "10px", color : "#EB3131" }} />
                                            <ListItemText primary={frustration} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}