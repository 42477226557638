import React, { useState, useEffect, useRef } from 'react';
import { AppBar, 
  Toolbar, 
  Button, 
  IconButton, 
  Typography, 
  Box, 
  useTheme, 
  useMediaQuery, 
  Menu, 
  MenuItem, 
  Divider 
} from '@mui/material';
import '../../assets/styles/header.css';
import Logo from '../../assets/images/logo.png';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import IdiotQuizModal from '../sharedComponents/IdiotQuizModal';

export const PageHeader = ({ handlePartSelect, page = 'home' }) => {

  const theme = useTheme();

  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showFirstAppBar, setShowFirstAppBar] = useState(true);
  const [anchorElPartSelect, setAnchorElPartSelect] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenPartSelectMenu = (event) => {
    setAnchorElPartSelect(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClosePartSelectMenu = () => {
    setAnchorElPartSelect(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePartSelectClick = ( partName ) => {
    handlePartSelect(partName);
  }

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setShowFirstAppBar(true);
    } else {
      setShowFirstAppBar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleModalClose = (status) =>{
    setModalOpen(status);
  }

  const handleQuizModalOpen = () => {
    setModalOpen(true);
  }

  return (
    <>
      {showFirstAppBar && (
        <AppBar position="static" sx={{ bgcolor: "white" }}>
          <Toolbar sx={{ borderBottom: "1px solid lightgrey" }} className='firstToolbar'>
            <img src={Logo} width={ isMobile ? `50%` : '15%'} alt="Logo" />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
            { !isMobile &&
              <Box>
                <Button className='headerButton'><LightbulbOutlinedIcon sx={{ mr: 1 }} />Consultation</Button>
                <Button className='headerButton' sx={{ ml: 3 }}><AccountCircleOutlinedIcon sx={{ mr: 1 }} />Login</Button>
              </Box>
            }
          </Toolbar>
        </AppBar>
      )}

      <AppBar position="sticky" sx={{ bgcolor: "white" }}>
        <Toolbar className='secondToolbar'>
          {!isMobile &&
            <>
              { page === 'home' &&
                <Box>
                  <Button className='headerButton partSelectButton' onClick={() => handlePartSelectClick('search')}>Search</Button>
                  <Button className='headerButton partSelectButton' sx={{ ml: 3 }} onClick={() => handlePartSelectClick('content')}>Idiotic Statements</Button>
                  <Button className='headerButton partSelectButton' sx={{ ml: 3 }} onClick={() => navigate('/persona')}>Idiot Badges</Button>
                </Box>
              }
              {
                page === 'persona' &&
                <Box>
                  <Button className='headerButton partSelectButton' onClick={() => navigate('/')}>Home</Button>
                </Box>
              }
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
              <Box>
                <Button className='headerButton'>What's your stance on EVs?</Button>
                <Button className='headerButton makeSelectionButton' sx={{ ml: 1 }} onClick={handleQuizModalOpen}>Make Selection</Button>
              </Box>
            </>
          }
          {
            isMobile &&
            <>
              <Box>
                <Button className='headerButton' sx={{ fontSize: "12px" }}>What's your stance on EVs?</Button>
                <Button className='headerButton makeSelectionButton' sx={{ ml: 1, fontSize: "12px" }} onClick={handleQuizModalOpen}>Make Selection</Button>
              </Box>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenPartSelectMenu} sx={{ p: 0 }}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElPartSelect}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElPartSelect)}
                  onClose={handleClosePartSelectMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Button className='headerButton'>Login</Button>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Button className='headerButton'>Consultation</Button>
                  </MenuItem>
                  {
                    page === 'home' && [
                      <Divider />,
                      <MenuItem onClick={handleClosePartSelectMenu}>
                        <Button className='headerButton' onClick={() => handlePartSelectClick('search')}>Search</Button>
                      </MenuItem>,
                      <Divider />,
                      <MenuItem onClick={handleClosePartSelectMenu}>
                        <Button className='headerButton' onClick={() => handlePartSelectClick('content')}>Idiotic Statements</Button>
                      </MenuItem>,
                      <Divider />,
                      <MenuItem onClick={handleClosePartSelectMenu}>
                        <Button className='headerButton' onClick={() => navigate('/persona')}>Idiot Badges</Button>
                      </MenuItem>
                  ]}
                  {
                    page === 'persona' && [
                      <Divider />,
                      <MenuItem onClick={handleClosePartSelectMenu}>
                        <Button className='headerButton' onClick={() => navigate('/')}>Home</Button>
                      </MenuItem>,
                  ]}
                </Menu>
              </Box>
            </>
          }
          <IdiotQuizModal modalOpen={modalOpen} handleCloseModal={handleModalClose} />
        </Toolbar>
      </AppBar>
    </>
  );
};
