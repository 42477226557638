import React, { useState } from 'react';
import { PageHeader } from '../components/page/Header.jsx';
import { PageFooter } from '../components/page/Footer.jsx';
import { Main } from '../components/page/Main.jsx';

export const HomePage = () => {

  const [selectedPart, setSelectedPart] = useState('');

  const handleSelectPart = (partName) => {
    setSelectedPart(partName);
    setTimeout(() => {
      setSelectedPart('');
    }, 500)
  }

  return (
    <>
      <PageHeader handlePartSelect={handleSelectPart} />
      <Main selectedPart={selectedPart} />
      <PageFooter />
    </>
  );
};
