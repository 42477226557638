const initialState = {
    value: '',
};
  
const quizReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DECISION':
            return {
                ...state,
                value: action.payload,
            };
        default:
            return state;
    }
};
  
  export default quizReducer;
  