import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import QuestionImg from '../../assets/images/questionMark.png';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from '../../actions';
import { quizData } from '../../assets/sampleContent/quizContent';

// Styled Components for consistent pixel-perfect design
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'end',
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const StyledHeaderText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontFamily: '"Anton", sans-serif !important',
    fontSize: '36px'
}));

const StyledDarkButton = styled(Button)(({theme}) => ({
  backgroundColor : "#536674",
  color : "white",
  width : "200px",
  height: "60px"
}));

const StyledLightButton = styled(Button)(({theme}) => ({
  backgroundColor : "transparent",
  color : "#536674",
  width : "200px",
  height: "60px",
  border : "1px solid #536674"
}));

const CustomRadio = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 20,
  height: 20,
  borderRadius: '50%',
  '&.Mui-checked': {
    background: "black",
    '&:after': {
      content: '""',
      display: 'block',
      width: 12,
      height: 12,
      borderRadius: '50%',
      margin: '4px',
      background: '#fff',
    },
  },
}));

const StyledDescText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontFamily: '"Poppins", sans-serif !important',
    fontSize: '20px'
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  padding: theme.spacing(1.5, 4),
  borderRadius: '8px',
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'left',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  padding: "20px 10px",
  border : "1px solid lightgrey",
  borderRadius : "10px",
  marginTop : "15px",
  marginLeft : "0px",
  marginRight : "0px"
}));

const IdiotQuizModal = ({ modalOpen, handleCloseModal }) => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [open, setOpen] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [quizDecision, setQuizDecision] = useState('');

  const dispatch = useDispatch();

  function getQuizFinalDecision() {
    const frequencyMap = {};
    let maxFrequency = 0;
    let finalAnswer = null;

    for (const key in answers) {
        const element = answers[key];
        frequencyMap[element] = (frequencyMap[element] || 0) + 1;

        if (frequencyMap[element] > maxFrequency) {
            maxFrequency = frequencyMap[element];
            finalAnswer = element;
        }
    }

    setQuizDecision(finalAnswer);
}

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen])

  const handleModalClose = () => {
    setOpen(false);
    handleCloseModal(false);
  }

  const handleNext = (isFinish) => {
    if(isFinish){
      getQuizFinalDecision();
    }

    if (step < quizData.length) setStep(step + 1);
    
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const handleAnswer = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
  };

  const handleViewContent = () => {
    dispatch(setValue(quizDecision));
    handleModalClose();
  }

  const renderStepContent = () => {
    if (step >= quizData.length) {
      return (
        <Box textAlign="center">
          <StyledHeaderText variant="h4" gutterBottom>
            It's official!
          </StyledHeaderText>
          <StyledDescText variant="h6" gutterBottom>
            You're a...
          </StyledDescText>
          <Box className="idiotQuizResultBox">
            <img src={QuestionImg} />
            <Typography fontSize="24px" fontWeight="700">{ quizDecision }</Typography>
            <Typography fontSize="18px" fontWeight="500">You're there are props and cons when it comes to EVs. [More description about the persona goes here]</Typography>
          </Box>
          <Typography variant="body1" paragraph>
            The right content for you is waiting behind the click of this button:
          </Typography>
          <StyledDarkButton variant="contained" sx={{ marginTop : "30px" }} onClick={handleViewContent}>
            View Content
          </StyledDarkButton>
        </Box>
      );
    }

    const { id, question, description,options } = quizData[step];
    return (
      <>
        <StyledHeaderText variant="h6" gutterBottom>
          {question}
        </StyledHeaderText>
        <Typography>{description}</Typography>
        <StyledRadioGroup
          onChange={(e) => handleAnswer(id, e.target.value)}
          value={answers[id] || ''}
          className='quizRadioGroup'
        >
          {options.map((option, index) => (
            <StyledFormControlLabel
              key={index}
              value={option.idiot}
              control={<Radio icon={<CustomRadio />} checkedIcon={<CheckIcon sx={{ color : "#536674" }} />} />}
              label={option.text}
            />
          ))}
        </StyledRadioGroup>
      </>
    );
  };

  return (
    <Dialog open={open} onClose={handleModalClose} maxWidth="xl" fullWidth>
        <StyledDialogTitle><CloseIcon sx={{ cursor: "pointer" }} onClick={handleModalClose} /></StyledDialogTitle>
        {
          !showQuiz &&
          <StyledDialogContent sx={{ display : "flex", flexDirection: "column", alignItems : "center", height: "100vh", justifyContent: "space-around", padding: "10%" }}>
            <Box>
              <StyledHeaderText>What kind of idiot are you?</StyledHeaderText>
              <StyledDescText>We're all idiots here.<br/>Find the content that best fits you by answering a few simple questions</StyledDescText>
            </Box>
            <Box sx={{ my : 5 }}>
              <StyledDarkButton onClick={() => setShowQuiz(true)}>Take the quiz</StyledDarkButton> < br />
              <StyledLightButton sx={{ marginTop : "10px" }}  onClick={handleModalClose}>I don't like to have fun</StyledLightButton>
            </Box>
            <Box>
              <StyledDescText sx={{ fontWeight: "700" }}>Don't think you're an idiot at all?</StyledDescText>
              <StyledDescText>You may be taking life a little too seriously. Try a couple of deep breaths,<br /> loosen up, and take the quiz!</StyledDescText>
            </Box>
          </StyledDialogContent>
        }
        {
          showQuiz &&
          <StyledDialogContent sx={{ height : "100vh" }}>
            <StyledStepper activeStep={step} alternativeLabel>
                {quizData.map((_, index) => (
                <Step key={index}>
                    <StepLabel />
                </Step>
                ))}
            </StyledStepper>

            {renderStepContent()}

            <Box className="quizActions">
              {(step > 0 && step < quizData.length) && (
                <StyledLightButton onClick={handleBack} variant="outlined">
                  Back
                </StyledLightButton>
                )}
                {step < quizData.length && (
                <StyledDarkButton onClick={() => handleNext(step === quizData.length - 1)} variant="contained">
                  {step === quizData.length - 1 ? 'Finish' : 'Continue'}
                </StyledDarkButton>
              )}
            </Box>
          </StyledDialogContent>
        }
        
    </Dialog>
  );
};

export default IdiotQuizModal;
